:root {
  --dark-blue: #10458c; /* Footer divider */
  --light-blue: #006fba; /* Destination markers, login image */
  --light-blue-opacity4: rgba(0, 111, 186, 0.04); /* Input image upload drop */
  --malibu-blue: #7aaef6; /* Destination list section border */
  --zircon-blue: #f4f9ff; /* Destination list section background, table row hover background */
  --yellow: #fbbe00; /* Self Assessment table status in progress, Route markers */
  --yellow-opacity80: #fbbe00cc; /* Self Assessment table status in progress */
  --silk-yellow: #fff7dd; /* Route list section background */
  --crayola-yellow: #fdcb33; /* Route list section border */
  --green: #89bd23; /* Self Assessment table status finished */
  --green-opacity80: #89bd23cc; /* Self Assessment table status finished */
  --coral-red: #f25d68; /* Delete button */
  --valencia-red: #d6404b; /* Delete button hover */
  --fair-pink: #ffecec; /* Error message background */
  --white: #ffffff; /* Background, text */
  --chateau-grey: #9aa0ac; /* User menu user email, self Assessment table status started */
  --chateau-grey-opacity80: #9aa0accc; /* Self Assessment table status started */
  --silver-grey: #cdcdcd; /* Scrollbar background */
  --gallery-grey: #f0f0f0; /* Scrollbar track */
  --athens-grey: #f4f5f7; /* Body background */
  --alto-grey: #e1e5eb; /* Footer border */
  --black-opacity3: rgba(0, 0, 0, 0.03); /* Box shadow */
  --black-opacity6: rgba(0, 0, 0, 0.06); /* Box shadow, destination list section border */
  --black-opacity9: rgba(0, 0, 0, 0.09); /* Search bar border, form input divider */
  --black-opacity15: rgba(0, 0, 0, 0.15); /* Language switcher border */
  --black-opacity23: rgba(0, 0, 0, 0.23); /* Inputs border */
  --black-opacity26: rgba(0, 0, 0, 0.26); /* Destinations sort icon */
  --black-opacity50: rgba(0, 0, 0, 0.5); /* Dashboard table action icon */
  --black-opacity70: rgba(0, 0, 0, 0.7); /* Secondary text (menu dropdown, build id, search) */
  --black-opacity90: rgba(0, 0, 0, 0.9); /* Primary text (user name, icon) */

  --search-bar-height: 70px;
  --footer-height: 100px;
  --footer-build-id-height: 30px;
  --drawer-close-width: 15px;
  --destinations-search-width: 300px;

  --font-roboto: "Roboto";
}

/*
* APP
*/

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

body {
  background-color: var(--athens-grey);
  margin: 0;
}

ul {
  padding-inline-start: 0px;
}

.input-clickable {
  cursor: pointer;
}

a {
  text-decoration: none;
}
.input-cursor-deafult {
  cursor: default;
}

/**
* MAIN
*/

.main-container {
  flex: 1;
}

/**
* HEADER
*/

.appbar-container,
.appbar-container-transparent {
  box-shadow: 0px 2px 4px var(--black-opacity6) !important;
  background-color: var(--white) !important;
}

.header-container {
  height: 100%;
}

.header-container .item-container-login-button,
.header-container .item-container-user-menu {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.header-container .item-container-user-menu {
  padding-left: 24px;
}

@media (min-width: 960px) {
  .appbar-container-transparent {
    position: absolute !important;
    right: 0px;
    top: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
    z-index: 2;
    width: auto !important;
  }

  .appbar-container-transparent .item-container-user-menu {
    padding-left: 12px;
  }

  .appbar-container-transparent .item-container-language-switcher {
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media (min-width: 600px) {
  .header-container {
    height: 64px;
  }
}

/**
* Login button
*/

.button-login {
  text-transform: none !important;
}

.button-login-link {
  text-decoration: none;
  margin-left: 24px;
}

@media (min-width: 960px) {
  .button-login-link {
    margin-left: 12px;
  }
}

/**
* Language switcher
*/

.language-switcher-button {
  height: 100%;
  background-color: var(--white) !important;
  border-right: 1px solid var(--black-opacity15) !important;
  border-left: 1px solid var(--black-opacity15) !important;
  border-radius: 0px !important;
}

.language-switcher-item-flag {
  width: 1.25rem !important;
  height: 1.25rem !important;
}

.language-switcher-dropdown-container {
  margin-top: 2px;
}

.language-switcher-item,
.language-switcher-item-floating {
  padding: 8px 15px !important;
  justify-content: center !important;
}

.language-switcher-item:hover,
.language-switcher-item-floating:hover {
  background-color: var(--zircon-blue) !important;
}

@media (min-width: 960px) {
  .appbar-container-transparent .language-switcher-button {
    height: 100%;
    background-color: var(--white) !important;
    border: 1px solid var(--black-opacity15) !important;
    min-width: 0px !important;
    padding: 9px !important;
    margin-top: auto;
    margin-bottom: auto;
    height: auto;
    border-radius: 4px !important;
  }

  .appbar-container-transparent .language-switcher-dropdown-container {
    margin-top: 5px;
  }

  .language-switcher-item-floating {
    padding: 8px 10px !important;
  }
}

/**
* User menu
*/

.user-menu-user-container {
  padding-left: 8px;
}

.user-menu-user-container .user-name {
  color: var(--black-opacity90);
  font-size: 0.875rem !important;
  line-height: 12px !important;
}

.user-menu-user-container .arrow-icon {
  font-size: 1.125rem !important;
  color: var(--black-opacity90);
}

.user-menu-user-container .user-email {
  font-size: 0.75rem !important;
  color: var(--chateau-grey);
}

.user-menu-dropdown-container-floating,
.user-menu-dropdown-container {
  margin-top: 13px !important;
}

.user-menu-item-row-container {
  padding: 5px 10px;
  outline: none;
}

.user-menu-item-link-container {
  width: 100%;
}

.user-menu-item-container {
  width: 100px;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
}

.user-menu-item-title {
  color: var(--black-opacity70) !important;
}

.user-menu-item-container:hover {
  background-color: var(--zircon-blue);
}

.user-menu-item-icon {
  height: 30px;
  width: 30px;
  margin: 5px;
}

.user-menu-user-settings-container {
  text-align: center;
  padding: 15px 5px;
}

.user-menu-user-settings-container .user-name {
  font-size: 0.875rem !important;
  font-weight: 500;
}

.user-menu-user-settings-container .user-email {
  font-size: 0.875rem !important;
}

.user-menu-change-password-container {
  margin: auto !important;
  margin-top: 6px !important;
  width: fit-content;
}

.user-menu-sign-out-container {
  margin-top: 12px !important;
}

.user-menu-user-settings-container .change-password {
  font-size: 0.75rem !important;
  color: var(--black-opacity50);
}

.user-menu-user-settings-container .change-password:hover {
  color: var(--black-opacity70);
}

.sign-out-button {
  text-transform: none !important;
  color: var(--black-opacity50) !important;
  border-color: var(--chateau-grey) !important;
  font-size: 0.875rem !important;
}

.sign-out-button:hover {
  background-color: var(--athens-grey) !important;
}

.user-menu-dropdown-icon {
  font-size: 1rem !important;
  color: var(--chateau-grey);
}

.user-menu-dropdown-divider {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.user-menu-avatar {
  height: 35px !important;
  width: 35px !important;
}

@media (max-width: 960px) and (min-width: 600px) {
  .user-menu-dropdown-container-floating,
  .user-menu-dropdown-container {
    margin-top: 16px !important;
  }
}

@media (min-width: 960px) {
  .appbar-container-transparent .user-menu-user-container {
    display: none;
    padding-left: 0px;
  }

  .user-menu-dropdown-container-floating {
    margin-top: 5px !important;
  }

  .user-menu-dropdown-container {
    margin-top: 16px !important;
  }
}

/**
* App menu
*/

.app-menu {
  height: 100%;
}

.app-menu-item-container {
  height: 100%;
}

.app-menu-item,
.app-menu-item-active {
  background-color: transparent !important;
  border-width: 0px 0px 3px 0px !important;
  border-style: solid !important;
  border-radius: 0px !important;
  text-transform: none !important;
  margin: 0px 10px !important;
  height: 100%;
  padding-top: 10px !important;
}

.app-menu-item {
  border-color: transparent !important;
  color: var(--black-opacity50) !important;
}

.app-menu-item-active {
  border-color: var(--malibu-blue) !important;
  color: var(--black-opacity70) !important;
}

.app-menu-item:hover,
.app-menu-item-active:hover {
  color: var(--black-opacity70) !important;
}

/**
* FOOTER
*/

.footer-container {
  padding: 20px 5px 20px var(--drawer-close-width);
  height: auto;
  background-color: var(--white);
  border-top: 1px solid var(--alto-grey);
}

.footer-divider {
  margin-left: 30px !important;
  margin-right: 30px !important;
  background-color: var(--dark-blue) !important;
  width: 0.5px !important;
}

.footer-acknowledgment {
  color: var(--dark-blue);
  font-size: 11px !important;
}

.footer-acknowledgment a {
  color: var(--dark-blue);
  font-weight: 500;
  text-decoration: none;
}

.footer-interreg-logotype {
  height: 50px;
}

.footer-eurostat-logotype {
  height: 15px;
}

.footer-social-media {
  height: 22px;
  margin-right: 8px;
}

.footer-build-id-container {
  border-top: 1px solid var(--alto-grey);
  height: var(--footer-build-id-height);
  line-height: var(--footer-build-id-height);
  background-color: var(--white);
  text-align: right;
  padding-right: 24px;
}

.footer-build-id {
  color: var(--black-opacity70);
  font-size: 10px !important;
  display: inline-block;
  vertical-align: middle;
}

.footer-container-logotype {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.footer-container-logotype,
.footer-container-acknowledgment,
.footer-container-social-media {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.footer-container,
.footer-container-logotype,
.footer-container-acknowledgment,
.footer-container-social-media {
  text-align: center;
}

@media (max-width: 1280px) and (min-width: 960px) {
  .footer-interreg-logotype {
    height: 45px;
  }

  .footer-eurostat-logotype {
    height: 13px;
  }
}

@media (min-width: 960px) {
  .footer-container {
    padding: 20px 0px 20px 0px;
    height: var(--footer-height);
  }

  .footer-container-acknowledgment {
    width: max-content;
  }

  .footer-container,
  .footer-container-logotype,
  .footer-container-acknowledgment,
  .footer-container-social-media {
    text-align: left;
  }

  .footer-container-logotype,
  .footer-container-acknowledgment,
  .footer-container-social-media {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .footer-container-logotype {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

.footer-datasource {
  padding: 0px !important;
}

.footer-datasource-logotypes {
  padding: 0px !important;
  padding-top: 5px !important;
  padding-right: 5px !important;
}

/**
* DESTINATIONS
*/

/**
* Destinations layout
*/

.destinations-layout-container-right {
  flex-grow: 1;
  height: 100vh;
  overflow-y: auto;
  width: calc(100% - var(--destinations-search-width));
}

.destinations-layout-container-left {
  width: var(--destinations-search-width);
  flex: 1 0 var(--destinations-search-width);
}

@media (min-width: 960px) {
  .destinations-layout-container-left {
    flex-grow: 0;
  }
}

/**
* Map
*/

.leaflet-container {
  width: auto;
  height: calc(100vh - var(--footer-height) - var(--footer-build-id-height) - 2px);
  z-index: 1;
}

/**
* Map marker
*/

.map-marker-cluster-destinations {
  background: var(--light-blue) !important;
  border: 2px solid var(--light-blue);
}

.map-marker-cluster-routes {
  background: var(--yellow) !important;
  border: 2px solid var(--yellow);
}

.map-marker-cluster-xs {
  background: var(--light-blue);
  border-radius: 50%;
  height: 28px !important;
  width: 28px !important;
}

.map-marker-cluster-sm {
  background: var(--light-blue);
  border-radius: 50%;
  height: 31px !important;
  width: 31px !important;
}

.map-marker-cluster-md {
  background: var(--light-blue);
  border-radius: 50%;
  height: 34px !important;
  width: 34px !important;
}

.map-marker-cluster-lg {
  background: var(--light-blue);
  border-radius: 50%;
  height: 37px !important;
  width: 37px !important;
}

.map-marker-cluster-xl {
  background: var(--light-blue);
  border-radius: 50%;
  height: 40px !important;
  width: 40px !important;
}

.map-marker-cluster-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--white);
  font-family: var(--font-roboto);
  font-size: 13px;
  font-weight: 500 !important;
}

.map-marker-cluster-label-routes {
  color: var(--white);
}

/**
* Map popup
*/

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: transparent;
  color: transparent;
  box-shadow: none;
}

.leaflet-popup-content {
  width: 400px !important;
}

.leaflet-popup-content p {
  margin: 0;
}

/**
* Map route lines
*/
.polyline-animate,
.route-marker-animate,
.destination-marker-animate {
  transition: stroke-width 0.2s ease-in;
}
.polyline-animate:hover,
.route-marker-animate:hover,
.destination-marker-animate:hover {
  stroke-width: 10;
}

/**
* Search
*/

.destination-search-list {
  margin-left: var(--drawer-close-width);
  background-color: var(--white);
}

.destination-search-bar {
  padding-right: 10px !important;
  box-shadow: 0px 2px 2px var(--black-opacity3);
  height: var(--search-bar-height);
  border: 1px solid var(--black-opacity9);
}

.destination-search-input {
  width: 90%;
  font-size: 0.9375rem !important;
  padding: 10px 9px;
  color: var(--black-opacity70) !important;
}

.destination-search-icon {
  padding-right: 0px !important;
}

.destination-sort-icon {
  padding-right: 0px !important;
  color: var(--black-opacity26) !important;
}

/**
* List of destinations
*/

@media (min-width: 960px) {
  .destination-route-list {
    height: calc(100vh - var(--search-bar-height));
    overflow-y: auto;
    border-right: 1px solid var(--alto-grey);
  }
}

.destination-route-list {
  scrollbar-width: thin;
}

.destination-route-list::-webkit-scrollbar {
  width: 7px;
}

.destination-route-list::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

.destination-route-list::-webkit-scrollbar-track {
  background-color: var(--gallery-grey);
}

.destination-route-list::-webkit-scrollbar-thumb {
  background: var(--silver-grey);
  border-radius: 2px;
}

.destination-route-list .MuiAccordionDetails-root {
  padding: 5px 0px;
}

.destination-list-section-container,
.route-list-section-container {
  padding-left: 16px !important;
  padding-right: 24px !important;
  border-top: 1px solid var(--black-opacity6) !important;
  border-bottom: 1px solid var(--black-opacity6) !important;
  box-shadow: 0px 2px 2px var(--black-opacity3) !important;
}

.destination-list-section-container {
  background-color: var(--zircon-blue) !important;
  border-left: 10px solid var(--malibu-blue) !important;
}

.route-list-section-container {
  background-color: var(--silk-yellow) !important;
  border-left: 10px solid var(--crayola-yellow) !important;
}

.destination-route-list-section-group {
  font-size: 0.9375rem !important;
}

.destination-route-list-name {
  font-size: 0.8125rem !important;
  padding-right: 10px;
  padding-left: 30px;
  color: var(--black-opacity70);
}

.destination-route-list-section-name {
  font-size: 0.875rem !important;
}

/**
* LOGIN, CHANGE PASSWORD
*/

.login-container,
.change-password-container {
  height: 100vh;
}

.login-image,
.change-password-image {
  background-color: var(--light-blue);
  height: 100%;
}

.login-content-container,
.change-password-content-container {
  height: 100%;
}

.login-logotype-container,
.change-password-logotype-container {
  margin-bottom: 50px !important;
}

.login-submit-button,
.change-password-submit-button {
  margin-top: 40px !important;
  text-transform: none !important;
}

/**
* DASHBOARD
*/

.dashboard-container {
  margin-left: 15px;
  padding-bottom: 48px;
  padding-top: 48px;
}

.dashboard-container .subtitle {
  font-size: 0.938em;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.dashboard-container .title {
  font-weight: 500 !important;
  font-size: 1.625rem !important;
}

.dashboard-container .add-button-container {
  text-align: left;
  margin-top: 0.75rem !important;
}

.dashboard-container .add-button-container a,
.dashboard-container .add-button-container a:hover,
.dashboard-container .add-button-container a:focus {
  outline: none;
  text-decoration: none;
}

.dashboard-container .add-button {
  text-transform: none;
}

.dashboard-container .table-container {
  padding-top: 50px;
  height: 500px;
}

.dashboard-container .table .MuiDataGrid-columnsContainer {
  cursor: pointer;
}

.dashboard-container .table .MuiDataGrid-dataContainer .rendering-zone {
  cursor: pointer;
}

.dashboard-container .table .MuiDataGrid-colCell:focus,
.dashboard-container .table .MuiDataGrid-cell:focus {
  outline: none;
}

.dashboard-container .table .MuiDataGrid-row:hover {
  background-color: var(--zircon-blue) !important;
}

@media (min-width: 600px) {
  .dashboard-container .add-button-container {
    text-align: right;
    margin-top: 0px !important;
  }
}

/**
* ADD CULTURAL ROUTE FORM
*/

.form-cultural-route-container {
  margin-left: 15px;
  padding-bottom: 48px;
  padding-top: 48px;
}

.form-cultural-route-container .title {
  font-weight: 500 !important;
  font-size: 1.625rem !important;
}

.form-cultural-route-container .add-button-container,
.form-cultural-route-container .save-button-container,
.form-cultural-route-container .edit-delete-button-container {
  text-align: left;
  margin-top: 0.75rem !important;
}

.form-cultural-route-container .edit-delete-button,
.form-cultural-route-container .save-cancel-button {
  justify-content: flex-start;
}

.form-cultural-route-container .add-button-container a,
.form-cultural-route-container .add-button-container a:hover,
.form-cultural-route-container .add-button-container a:focus,
.form-cultural-route-container .save-button-container a,
.form-cultural-route-container .save-button-container a:hover,
.form-cultural-route-container .save-button-container a:focus,
.form-cultural-route-container .edit-delete-button-container a,
.form-cultural-route-container .edit-delete-button-container a:hover,
.form-cultural-route-container .edit-delete-button-container a:focus {
  outline: none;
  text-decoration: none;
}

.form-cultural-route-container .add-button,
.form-cultural-route-container .save-button,
.form-cultural-route-container .cancel-button,
.form-cultural-route-container .edit-button,
.form-cultural-route-container .back-button,
.form-cultural-route-container .delete-button {
  text-transform: none;
}

.form-cultural-route-container .back-button-icon {
  margin-right: 6px;
}

.form-cultural-route-container .delete-button {
  background-color: var(--coral-red);
}

.form-cultural-route-container .delete-button:hover {
  background-color: var(--valencia-red);
}

.form-cultural-route-container .input-divider {
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: var(--black-opacity9);
}

.form-cultural-route-container .form-input-label {
  font-weight: bold !important;
}

.form-cultural-route-container .form-input-instructions {
  margin-top: 6px !important;
}

@media (min-width: 600px) {
  .form-cultural-route-container .add-button-container,
  .form-cultural-route-container .save-button-container,
  .form-cultural-route-container .edit-delete-button-container {
    text-align: right;
    margin-top: 0px !important;
  }

  .form-cultural-route-container .edit-delete-button,
  .form-cultural-route-container .save-cancel-button {
    justify-content: flex-end;
  }

  .form-cultural-route-container .input-divider {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

/**
* Dual listbox for choosing route points
*/
.react-dual-listbox {
  height: 300px;
}
.rdl-control-container {
  background-color: white;
}
.rdl-control {
  border-color: var(--black-opacity23);
}
.rdl-move.rdl-move-right,
.rdl-move.rdl-move-left {
  border-color: var(--black-opacity23);
  border-bottom: none;
}
.rdl-control option {
  font-family: var(--font-roboto);
  color: transparent;
  text-shadow: 0 0 0 #000;
}
.rdl-filter {
  border-color: var(--black-opacity23);
}
.rdl-move-top {
  border-right: solid 1px var(--black-opacity23) !important;
  border-radius: 2px !important;
  margin-bottom: 5px !important;
}

/**
* Input text field
*/

.form-cultural-route-container .input-text-field,
.form-cultural-route-container .input-text-field-validation-message {
  width: 100%;
}

.form-cultural-route-container .input-text-field .MuiOutlinedInput-root,
.form-cultural-route-container .input-text-field-validation-message .MuiOutlinedInput-root {
  background-color: var(--white);
  border-radius: 5px !important;
  font-size: 0.875em;
}

.form-cultural-route-container
  .input-text-field
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline,
.form-cultural-route-container
  .input-text-field
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
  border-color: var(--black-opacity23);
}

.form-cultural-route-container
  .input-text-field-validation-message
  .MuiOutlinedInput-root
  .MuiOutlinedInput-notchedOutline,
.form-cultural-route-container
  .input-text-field-validation-message
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline,
.form-cultural-route-container
  .input-text-field-validation-message
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
  border-color: var(--coral-red);
}

/**
* Validation message
*/

.validation-message {
  font-size: 0.75rem !important;
  color: #f25d68 !important;
  margin-top: 5px !important;
  position: absolute;
}

/**
* Input checkbox
*/

.form-cultural-route-container .input-checkbox,
.form-cultural-route-container .input-checkbox:hover,
.dashboard-container .input-checkbox,
.dashboard-container .input-checkbox:hover,
.Mui-checked:hover {
  background-color: transparent !important;
}

.dashboard-container .input-checkbox {
  margin-right: 5px;
}

.form-cultural-route-container .input-checkbox.MuiCheckbox-root,
.dashboard-container .input-checkbox.MuiCheckbox-root {
  padding: 0px;
}

.form-cultural-route-container .input-checkbox.Mui-disabled,
.dashboard-container .input-checkbox.Mui-disabled {
  color: var(--light-blue);
}

.form-cultural-route-container .input-checkbox-icon,
.dashboard-container .input-checkbox-icon {
  background-color: var(--white);
  border: 1px solid var(--black-opacity23);
  border-radius: 3px;
  height: 19px;
  width: 19px;
  margin: 2px;
}

.input-checkbox-icon-checked {
  height: 25px;
  width: 25px;
  padding: 0px;
}

.form-cultural-route-container .input-checkbox-label {
  font-size: 0.875em;
  display: inline;
  margin-left: 5px;
}

/**
* Input text editor
*/

.form-cultural-route-container .input-text-editor .ql-editor {
  min-height: 250px;
}

.form-cultural-route-container .input-text-editor-read-only .ql-editor {
  min-height: 0px;
  cursor: default;
}

.form-cultural-route-container .input-text-editor .ql-snow .ql-stroke {
  stroke-width: 1.5;
}

.form-cultural-route-container .input-text-editor .ql-snow .ql-thin,
.form-cultural-route-container .input-text-editor .ql-snow .ql-stroke.ql-thin {
  stroke-width: 1;
}

.form-cultural-route-container .input-text-editor .ql-toolbar.ql-snow {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 12px;
  border-color: var(--black-opacity23);
}

.form-cultural-route-container .input-text-editor .ql-container.ql-snow {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-family: var(--font-roboto);
  border-color: var(--black-opacity23);
  font-size: 0.875em;
}

.form-cultural-route-container .input-text-editor-read-only .ql-container.ql-snow {
  border-radius: 5px;
  font-family: var(--font-roboto);
  border-color: var(--black-opacity23);
  font-size: 0.875em;
}

.form-cultural-route-container .input-text-editor,
.form-cultural-route-container .input-text-editor-read-only {
  background-color: var(--white);
  border-radius: 5px;
}

/**
* Input card select
*/

.form-cultural-route-container .input-card-select-container {
  margin-right: 12px;
  margin-bottom: 12px;
}

.form-cultural-route-container .input-card-select,
.form-cultural-route-container .input-card-select-selected {
  width: 180px;
  background-color: var(--white);
  border-radius: 5px;
}

.form-cultural-route-container .input-card-select {
  padding: 15px;
  border: 1px solid var(--black-opacity23);
}

.form-cultural-route-container .input-card-select-selected {
  padding: 14px;
  border: 2px solid var(--light-blue);
}

/**
* Input image upload
*/

.form-cultural-route-container .input-image-upload-container {
  background-color: var(--white);
  border-radius: 5px;
  font-family: var(--font-roboto);
  padding: 12px;
  border: 1px solid var(--black-opacity23);
  color: var(--black-opacity90);
}

.form-cultural-route-container .input-image-upload-dropzone {
  text-align: center;
  border-radius: 5px;
  border: 1px dashed var(--black-opacity23);
  padding: 30px;
}

.form-cultural-route-container .input-image-upload-dropzone:focus,
.form-cultural-route-container .input-image-upload-dropzone-dropping {
  text-align: center;
  padding: 50px;
  border-radius: 5px;
  border: 1px dashed var(--light-blue);
  outline: none;
  background-color: var(--light-blue-opacity4);
  padding: 30px;
}

.form-cultural-route-container .input-image-upload-inputs-container {
  flex-grow: 1;
}

.form-cultural-route-container .input-image-upload-image-container {
  padding: 20px 0px;
  max-width: 900px;
}

.form-cultural-route-container .input-image-upload-image-preview-container {
  overflow: hidden;
  height: 100px;
  width: 100px;
  margin-right: 5px;
}

.form-cultural-route-container .input-image-upload-image-preview {
  object-fit: cover;
  height: 100%;
  width: 100%;
  background-color: var(--white);
}

.form-cultural-route-container .input-image-size {
  font-size: 0.6875em;
  margin-left: 4px;
  margin-top: 5px;
}

.form-cultural-route-container .input-image-divider {
  background-color: var(--black-opacity9);
}

.form-cultural-route-container .input-image-upload-text-container {
  height: 100%;
}

.form-cultural-route-container .input-image-upload-dropzone-icon {
  height: 40px;
  margin-bottom: 15px;
}

.form-cultural-route-container .error-messages-container,
.form-cultural-route-container .error-images-container {
  margin-top: 12px;
}

.form-cultural-route-container .error-message-container {
  background-color: var(--fair-pink);
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.form-cultural-route-container .error-message-icon-error-container {
  max-width: 35px;
  display: none;
}

.form-cultural-route-container .error-message-icon-error {
  margin-left: 5px;
  margin-right: 10px;
  height: 20px;
}

.form-cultural-route-container .error-message-text-container {
  max-width: calc(100% - 25px);
  flex-basis: calc(100% - 25px);
}

.form-cultural-route-container .error-message-text {
  color: var(--valencia-red) !important;
}

.form-cultural-route-container .error-message-icon-close-container {
  color: var(--valencia-red);
  max-width: 10px;
  margin-left: 10px;
  margin-right: 5px;
}

.form-cultural-route-container .error-message-icon-close {
  font-size: 1.0625rem !important;
  cursor: pointer;
}

@media (min-width: 420px) {
  .form-cultural-route-container .error-message-icon-error-container {
    display: block;
  }

  .form-cultural-route-container .error-message-text-container {
    max-width: calc(100% - 60px);
    flex-basis: calc(100% - 60px);
  }
}

@media (min-width: 600px) {
  .form-cultural-route-container .input-image-upload-container {
    padding: 20px;
  }

  .form-cultural-route-container .error-messages-container,
  .form-cultural-route-container .error-images-container {
    margin-top: 20px;
  }
}

/**
* Form cultural route routeColor select
*/
#parent-route-select .routeParentColorCircle {
  position: relative;
  top: 2px;
}
#parent-route-select .routeParentText {
  position: relative;
  bottom: 6px;
}
.colorButton button {
  margin: 0px;
  width: 54px;
  height: 54px;
  box-shadow: none;
  border: 1px solid #c4c4c4;
}
.colorButton button {
  pointer-events: none;
}
.colorPicker button {
  margin: 0px;
  width: 54px;
  height: 54px;
  margin-top: 40px;
  margin-right: 10px;
  box-shadow: none;
  border: 1px solid #c4c4c4;
}
.colorPicker button:hover {
  box-shadow: none;
}

.colorPicker button .MuiButton-label {
  display: none;
}
.colorPicker button:focus {
  box-shadow: none;
}

/**
* Input image upload & Input links & Input contacts & Input route parts
*/

.form-cultural-route-container .input-image-caption-text-field .MuiOutlinedInput-root,
.form-cultural-route-container .input-link-text-field .MuiOutlinedInput-root,
.form-cultural-route-container .input-contacts-text-field .MuiOutlinedInput-root,
.form-cultural-route-container .input-contacts-select .MuiOutlinedInput-root,
.form-cultural-route-container .input-route-parts-text-field .MuiOutlinedInput-root {
  background-color: var(--white);
  border-radius: 5px !important;
  font-size: 0.875em;
}

.form-cultural-route-container
  .input-image-caption-text-field
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline,
.form-cultural-route-container
  .input-link-text-field
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline,
.form-cultural-route-container
  .input-contacts-text-field
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline,
.form-cultural-route-container
  .input-contacts-select
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline,
.form-cultural-route-container
  .input-route-parts-text-field
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
  border-color: var(--black-opacity23);
}

.form-cultural-route-container
  .input-image-caption-text-field
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline,
.form-cultural-route-container
  .input-link-text-field
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline,
.form-cultural-route-container
  .input-contacts-text-field
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline,
.form-cultural-route-container
  .input-contacts-select
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline,
.form-cultural-route-container
  .input-route-parts-text-field
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
  border-color: var(--black-opacity23);
}

.form-cultural-route-container .input-image-caption-text-field .MuiFormLabel-root,
.form-cultural-route-container .input-link-text-field .MuiFormLabel-root,
.form-cultural-route-container .input-contacts-text-field .MuiFormLabel-root,
.form-cultural-route-container .input-contacts-select .MuiFormLabel-root,
.form-cultural-route-container .input-route-parts-text-field .MuiFormLabel-root {
  color: var(--black-opacity70);
  font-size: 0.875em;
}

.form-cultural-route-container .input-image-remove-btn-container,
.form-cultural-route-container .input-link-remove-btn-container,
.form-cultural-route-container .input-contacts-remove-btn-container,
.form-cultural-route-container .input-route-parts-remove-btn-container {
  width: 80px;
  text-align: left;
}

.form-cultural-route-container .input-image-remove-btn,
.form-cultural-route-container .input-link-remove-btn,
.form-cultural-route-container .input-link-add-more-btn,
.form-cultural-route-container .input-contacts-remove-btn,
.form-cultural-route-container .input-contacts-add-more-btn,
.form-cultural-route-container .input-route-parts-remove-btn,
.form-cultural-route-container .input-route-parts-add-more-btn {
  text-transform: none;
}

.form-cultural-route-container .input-image-remove-btn,
.form-cultural-route-container .input-link-remove-btn,
.form-cultural-route-container .input-contacts-remove-btn,
.form-cultural-route-container .input-route-parts-remove-btn {
  margin-top: 12px;
}

@media (min-width: 600px) {
  .form-cultural-route-container .input-link-inputs-container,
  .form-cultural-route-container .input-contacts-inputs-container,
  .form-cultural-route-container .input-route-parts-inputs-container {
    display: inline;
  }

  .form-cultural-route-container .input-link-container,
  .form-cultural-route-container .input-contacts-container,
  .form-cultural-route-container .input-route-parts-container {
    margin-bottom: 12px;
  }

  .form-cultural-route-container .input-image-remove-btn-container,
  .form-cultural-route-container .input-link-remove-btn-container,
  .form-cultural-route-container .input-contacts-remove-btn-container,
  .form-cultural-route-container .input-route-parts-remove-btn-container {
    text-align: right;
  }

  .form-cultural-route-container .input-image-remove-btn,
  .form-cultural-route-container .input-link-remove-btn,
  .form-cultural-route-container .input-contacts-remove-btn,
  .form-cultural-route-container .input-route-parts-remove-btn {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

/**
* Input links & Input contacts & Input route parts
*/

.form-cultural-route-container .input-link-container,
.form-cultural-route-container .input-contacts-container {
  width: 100%;
  margin-bottom: 24px;
  max-width: 900px;
}

.form-cultural-route-container .input-link-inputs-container,
.form-cultural-route-container .input-contacts-inputs-container,
.form-cultural-route-container .input-route-parts-inputs-container {
  flex-grow: 1;
  display: contents;
}

@media (min-width: 600px) {
  .form-cultural-route-container .input-link-inputs-container,
  .form-cultural-route-container .input-contacts-inputs-container,
  .form-cultural-route-container .input-route-parts-inputs-container {
    display: inline;
  }

  .form-cultural-route-container .input-link-container,
  .form-cultural-route-container .input-contacts-container,
  .form-cultural-route-container .input-route-parts-container {
    margin-bottom: 12px;
  }
}

/**
* Input contacts
*/

.form-cultural-route-container .input-contacts-select .MuiSelect-select:focus {
  background-color: transparent;
}

.input-contacts-inputs-dropdown-container ul li {
  font-size: 0.875em;
}

/**
* Input route parts
*/

.form-cultural-route-container .input-route-parts-container {
  width: 100%;
  margin-bottom: 24px;
  max-width: 600px;
}

/**
* Input route location
*/

.form-cultural-route-container .input-route-location-map-clickable {
  height: 400px;
  cursor: pointer;
}

.form-cultural-route-container .input-route-location-map {
  height: 400px;
}

.form-cultural-route-container .input-route-location-marker-not-clickable {
  cursor: default;
}

/**
* VIEW CULTURAL ROUTE FORM
*/

.form-cultural-route-container input:disabled,
.form-cultural-route-container div.MuiSelect-select[aria-disabled="true"] {
  color: var(--black-opacity90);
}

.form-cultural-route-container .input-image-upload-container-disabled {
  max-width: 900px;
}

.form-cultural-route-container .input-image-upload-dropzone-hidden {
  display: none;
}

.form-cultural-route-container .input-card-select-container-hidden {
  display: none;
}

/**
* Input route self assessment
*/

.form-cultural-route-container .table-container > div {
  height: 100% !important;
}

.form-cultural-route-container .table .MuiDataGrid-mainGridContainer {
  cursor: pointer;
}

.form-cultural-route-container .table .MuiDataGrid-dataContainer .rendering-zone {
  cursor: pointer;
}

.form-cultural-route-container .table .MuiDataGrid-colCell:focus,
.form-cultural-route-container .table .MuiDataGrid-cell:focus {
  outline: none;
}

.form-cultural-route-container .table .MuiDataGrid-row:hover {
  background-color: var(--zircon-blue) !important;
}

.self-assessment-status-IN-PROGRESS,
.self-assessment-status-FINISHED,
.self-assessment-status-STARTED {
  text-transform: uppercase;
  background-color: transparent;
  font-weight: 400;
  font-size: 0.813rem;
  color: var(--black-opacity90) !important;
}

.form-cultural-route-container .table .self-assessment-status-Not-started {
  text-transform: uppercase;
  background-color: transparent;
  font-weight: 400;
  font-size: 0.813rem;
}

.form-cultural-route-container
  .table
  .self-assessment-status-IN-PROGRESS
  span.self-assessment-status-text:first-letter,
.form-cultural-route-container
  .table
  .self-assessment-status-FINISHED
  span.self-assessment-status-text:first-letter,
.form-cultural-route-container
  .table
  .self-assessment-status-STARTED
  span.self-assessment-status-text:first-letter,
.form-cultural-route-container
  .table
  .self-assessment-status-Start-assessment
  span.self-assessment-status-text:first-letter {
  text-transform: uppercase;
}

.self-assessment-status-IN-PROGRESS {
  border: 1px solid var(--yellow-opacity80) !important;
  font-weight: 400 !important;
}

.self-assessment-status-FINISHED {
  border: 1px solid var(--green-opacity80) !important;
  font-weight: 400 !important;
}

.self-assessment-status-STARTED {
  border: 1px solid var(--chateau-grey-opacity80) !important;
  font-weight: 400 !important;
}

.self-assessment-status-Start-assessment {
  border: none;
  padding: 0px;
}

.self-assessment-status-indicator {
  font-size: 0.688rem !important;
  margin-right: 5px;
}

.form-cultural-route-container .self-assessment-status-FINISHED .self-assessment-status-indicator {
  color: var(--green) !important;
}

.form-cultural-route-container
  .self-assessment-status-IN-PROGRESS
  .self-assessment-status-indicator {
  color: var(--yellow) !important;
}

.form-cultural-route-container
  .self-assessment-status-Start-assessment
  .self-assessment-status-indicator {
  display: none;
}

.form-cultural-route-container .self-assessment-status-STARTED .self-assessment-status-indicator {
  color: var(--chateau-grey) !important;
}

.MuiDataGrid-root .MuiDataGrid-iconSeparator {
  opacity: 0;
}

.self-assessment-start-short,
.self-assessment-start-long {
  color: var(--green) !important;
  border: 1px solid var(--green-opacity80) !important;
}

.input-route-self-assessment-delete {
  color: var(--coral-red) !important;
  border: 1px solid var(--coral-red) !important;
}

/**
* Modal confirmation
*/

.modal-confirmation {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-confirmation-container {
  background-color: var(--white);
  border-radius: 5px;
  max-width: 400px;
  margin: 40px;
  outline: 0px;
}

.modal-confirmation-icon-close-container {
  text-align: right;
  color: var(--black-opacity70);
  margin: 10px;
}

.modal-confirmation-icon-close {
  font-size: 1.0625rem !important;
  cursor: pointer;
}

.modal-confirmation-icon-container {
  text-align: center;
}

.modal-confirmation-text-container {
  margin: 20px 30px !important;
  text-align: center;
}

.modal-confirmation-title {
  font-weight: bold !important;
  font-size: 1rem !important;
}

.modal-confirmation-description {
  margin-top: 10px !important;
}

.modal-confirmation-actions-container {
  margin-top: 20px !important;
  padding-bottom: 30px !important;
  padding-right: 30px !important;
  padding-left: 20px !important;
}

.modal-confirmation-actions-confirm-container {
  text-align: center;
}

.modal-confirmation-actions-confirm-container .delete-button {
  text-transform: none;
  background-color: var(--coral-red);
  font-size: 0.875em !important;
}

.modal-confirmation-actions-confirm-container .delete-button:hover {
  background-color: var(--valencia-red);
}

.modal-confirmation-actions-confirm-container .discard-changes-button {
  text-transform: none;
  font-size: 0.875rem !important;
}

.modal-confirmation-actions-cancel-container {
  text-align: center;
}

.modal-confirmation-actions-cancel-container .cancel-button {
  text-transform: none;
  background-color: transparent;
  font-size: 0.875rem !important;
  color: var(--black-opacity50);
  border-color: var(--black-opacity50);
}

.modal-confirmation-actions-cancel-container .cancel-button:hover {
  background-color: var(--black-opacity3);
  border-color: var(--black-opacity70);
}

@media (min-width: 600px) {
  .modal-confirmation-actions-cancel-container {
    text-align: left;
  }

  .modal-confirmation-actions-confirm-container {
    text-align: right;
  }

  .modal-confirmation-text-container {
    margin: 20px 40px !important;
  }
}

/*image carousel*/
@import "~react-image-gallery/styles/css/image-gallery.css";

/*Self-Assessment Questionnaire*/
.MuiStepConnector-alternativeLabel {
  top: 28px !important;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.MuiFormControlLabel-label {
  font-size: 0.93rem !important;
}

html {
  scroll-behavior: smooth;
}

.MuiSwitch-switchBase {
  background-color: transparent !important;
}

/*apply for account captcha*/
#reload_href {
  font-family: var(--font-roboto);
  color: var(--black-opacity-70) !important;
}
#canv {
  border-radius: 4px;
}
